.lpm-radio-option {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;

  + .lpm-radio-option {
    margin-top: 16px;
  }

  .lpm-radio-option-box {
    height: 24px;
    width: 24px;
    margin-right: 20px;
    border: 2px solid var(--dark-primary);
    border-radius: 50%;
    box-sizing: border-box;

    &.close {
      margin-right: 12px;
    }

    &.selected {
      background-color: var(--dark-primary);
    }
  }
}
