.profile {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.profile-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .content {
    margin-top: 36px;

    .picture-row {
      margin-bottom: 36px;
      display: flex;
      align-items: center;

      .picture {
        height: 180px;
        width: 180px;
        margin-right: 36px;
        border-radius: 50%;
        box-shadow: var(--big-shadow);
        object-fit: cover;
        cursor: pointer;

        &:active {
          opacity: 0.9;
        }

        &.placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--medium-gray);
          background-color: var(--light-gray);
          box-shadow: var(--shadow);
        }
      }

      .primary-info {
        .name {
          font-size: 36px;
        }

        .email {
          color: var(--medium-gray);
          font-size: 18px;
        }
      }
    }

    .sections-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: masonry;
      gap: 36px;
    }

    .section {
      &.personal-info {
        grid-row: 1 /3;
      }

      .section-contents {
        padding: 12px;
        background-color: var(--white);
        border-radius: 3px;
        box-shadow: var(--shadow);
      }

      .header {
        margin-bottom: 12px;
        color: var(--medium-gray);
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &.cont {
          margin-top: -16px;
        }

        &.separator {
          margin-top: -16px;
        }

        &.action {
          align-items: center;
          margin-bottom: 4px;
        }

        .title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
          vertical-align: top;
        }

        .value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);

          &.empty {
            color: var(--black);
            opacity: 0.25;
          }

          a {
            max-width: calc((100vw - var(--sidebar-width) - 88px - 48px) / 2 - 156px - 24px);
            display: inline;
            color: var(--orange);
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > a {
            color: var(--orange);
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
