.classes-table {
  tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
  }

  .cell-contents {
    &.flex-center {
      display: flex;
      align-items: center;
    }

    .course-logo {
      width: 56px;
      margin-right: 8px;
      object-fit: cover;
    }

    .ship-date {
      margin-top: 4px;
      color: var(--medium-gray);
      font-size: 14px;
    }
  }
}
