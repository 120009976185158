.update-teacher-info-modal {
  position: relative;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: 24px;
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .main-content-wrapper {
      width: 100%;
      display: flex;

      .col {
        flex: 1;

        &:not(:last-child) {
          margin-right: 48px;
        }

        .section-container {
          flex: 1;

          &:not(:first-child) {
            margin-top: 24px;
          }

          .section-title {
            margin-bottom: 8px;
            font-weight: 500;
            color: var(--darkGray);
          }

          .location-wrapper {
            margin-top: 18px;
            margin-bottom: 18px;
            display: flex;

            .location-number {
              margin: 7px 24px;
              font-size: 20px;
              font-weight: 500;
            }

            .location-rows {
              display: flex;
              flex-direction: column;

              .location-row {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                > :not(:first-child) {
                  margin-left: 24px;
                }

                .lpm-input + .lpm-input {
                  margin-top: 0;
                }

                &.triple .lpm-input + :not(.lpm-input) {
                  min-width: 150px;
                }
              }

              .remove-location {
                display: flex;
                align-items: center;
                align-self: flex-end;
                color: var(--red);
                font-weight: 500;
                cursor: pointer;

                &:hover {
                  opacity: 0.9;
                }

                &:active {
                  opacity: 0.7;
                }

                :first-child {
                  margin-right: 6px;
                }
              }
            }
          }
        }

        .add-location {
          display: inline-flex;
          align-items: center;
          align-self: flex-end;
          color: var(--green);
          font-weight: 500;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          &:active {
            opacity: 0.7;
          }

          :first-child {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
