.image-file-select {
  height: 400px;
  width: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .file-upload-preview {
    height: 400px;
    width: 400px;
    box-shadow: var(--shadow);
    cursor: pointer;
    object-fit: cover;
  }

  .file-upload-zone {
    height: 400px;
    width: 400px;
    padding: 16px;
    background-color: var(--light-gray);
    box-shadow: var(--shadow);
    cursor: pointer;
    user-select: none;

    &:active {
      opacity: 0.9;
    }

    .file-upload-zone-inner {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid var(--medium-gray);

      .file-upload-zone-label {
        padding: 36px;
        color: var(--medium-gray);
        font-size: 28px;
        text-align: center;
      }
    }
  }

  .hidden-file-input {
    display: none;
    visibility: hidden;
  }
}
