.wrapper {
  --title-row-height: 45px;
  --vertical-padding: 32px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: var(--vertical-padding) 44px 0px;
  background-color: var(--background);

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleRow {
    height: var(--title-row-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .content {
    --content-top-padding: 48px;
    --select-class-height: 64px;

    height: calc(var(--content-height) - var(--vertical-padding) - var(--title-row-height));
    padding-top: var(--content-top-padding);
    display: grid;
    grid-template-columns: 1.75fr 1fr;
    grid-template-rows: 1fr;
    gap: 64px;

    .sectionHeader {
      height: var(--header-height);
      margin-bottom: var(--header-bottom-margin);
      padding-top: 32px;
      color: var(--medium-gray);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;

      &.top {
        height: calc(var(--header-height) - 32px);
        padding-top: 0;
      }
    }

    .productsCol,
    .selectionsCol {
      height: calc(
        var(--content-height) - var(--vertical-padding) - var(--title-row-height) - var(--content-top-padding)
      );
    }

    .productsCol {
      .selectClassContainer {
        height: var(--select-class-height);
      }

      .resultsContainer {
        height: calc(100% - var(--select-class-height));
        margin-right: -24px;
        padding-bottom: var(--vertical-padding);
        padding-right: 24px;
        overflow-y: scroll;

        .materialItem {
          min-height: 108px;
          padding: 12px 20px 12px 12px;
          background-color: var(--white);
          box-shadow: var(--shadow);

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          .materialItemContent {
            height: 100%;
            display: flex;
            align-items: center;

            .previewImage {
              height: 84px;
              width: 84px;
              margin-right: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              object-fit: contain;

              &.fallback {
                background-color: #ddd;
              }
            }

            .infoContainer {
              flex: 1;
              align-self: stretch;
              display: flex;
              flex-direction: column;

              .title {
                font-size: 16px;
                font-weight: 500;
              }

              .info {
                font-size: 14px;
                color: var(--medium-gray);
              }

              .price {
                align-self: flex-end;
              }
            }

            .quantityContainer {
              width: 112px;
              margin-left: 32px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 28px;
              font-weight: 600;
              color: var(--dark-primary);

              > :not(:last-child) {
                margin-right: 12px;
              }

              .quantityIcon {
                cursor: pointer;

                &:active {
                  opacity: 0.8;
                }

                &.disabled {
                  opacity: 0.3;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }

    .selectionsCol {
      .noSelections {
        color: var(--medium-gray);
        font-size: 18px;
        text-align: center;
      }

      .termsAndConditionsDisclaimer {
        margin-top: 24px;
        text-align: center;
        font-size: 12px;
        color: var(--medium-gray);

        .termsAndConditionsLink {
          color: var(--orange);
          font-weight: 600;
        }
      }

      .materialsReminder {
        margin-top: 16px;
        text-align: center;
        font-size: 12px;
        color: var(--medium-gray);
      }
    }
  }
}
