.teacher-details {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.teacher-details-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }
}
