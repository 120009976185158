body {
  margin: 0;
  overflow: visible;
  overscroll-behavior: none;
}

.backer {
  position: fixed;
  top: -200vh;
  height: 500vh;
  width: 100vw;
  background-image: linear-gradient(var(--background), var(--background) 50%, #222 50.1%, #222 150%);
}

a {
  text-decoration: none;
  color: initial;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
}

.main-content {
  height: var(--content-height);
  width: 100vw;
  display: flex;
}
