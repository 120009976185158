.enrollments {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.enrollments-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }

    .search-input {
      width: 400px;
      min-width: 300px;
      max-width: 500px;
    }
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }
  }

  .filter-search-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .search-enrollments {
      min-width: 360px;
    }
  }

  .search-row {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-enrollments {
      min-width: 360px;
    }
  }

  .enrollments-table {
    td {
      height: 110px;
      padding: 16px 0 16px 16px;

      .cell-contents {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .course-logo {
          width: 72px;
          margin-right: 8px;
          object-fit: cover;
        }
      }
    }
  }
}
