.reset-password {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);

  .auth-content {
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message {
      z-index: 1;
      max-width: 70vw;
      margin-bottom: 56px;
      color: var(--dark-primary);
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .auth-title {
      margin: 0px;
      font-size: 26px;
      line-height: 24px;
      font-weight: 700;
    }

    .auth-square {
      width: 382px;
      margin-top: 20px;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

      .auth-logo {
        width: 70%;
      }

      .auth-inputs {
        width: 322px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .auth-button {
        height: 40px;
        width: 322px;
        margin-top: 30px;
        background-color: var(--dark-primary);
        color: #fff;
        outline: none;
        border: none;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        font-weight: 700;
        cursor: pointer;

        &:active {
          opacity: 0.9;
        }

        + .lpm-input {
          margin-top: 30px;
        }
      }

      .lpm-input-error + .auth-button {
        margin-top: 8px;
      }

      .redirect {
        margin-top: 30px;
        font-size: 14px;
        line-height: 22px;
        opacity: 0.3;
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.2;
        }

        .auth-link {
          margin-left: 8px;
          font-weight: 800;
        }
      }
    }
  }
}
