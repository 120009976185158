.teachers-table {
  tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
  }

  .cell-contents {
    &.flex-center {
      display: flex;
      align-items: center;
    }

    &.notes {
      min-width: 160px;
    }

    &.currently-teaching {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--medium-gray);
    }

    .profile-picture {
      height: 56px;
      width: 56px;
      margin-right: 16px;
      border-radius: 50%;
      object-fit: cover;

      &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        background-color: var(--light-gray);
        box-shadow: var(--shadow);

        &.female {
          color: var(--female-pink);
          background-color: var(--female-pink-semi-transparent);
        }

        &.male {
          color: var(--male-blue);
          background-color: var(--male-blue-semi-transparent);
        }
      }
    }

    .currently-teaching-course-logo {
      width: 72px;
      margin-right: 16px;
      object-fit: cover;
    }

    .aux {
      color: var(--medium-gray);
      font-size: 14px;
    }
  }
}
