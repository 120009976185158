.thank-you-wrapper {
  position: relative;
  width: var(--content-width);
  min-height: var(--content-height);
  padding: 48px 84px 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);

  .content {
    max-width: 650px;
  }

  .title {
    width: 100%;
    margin-bottom: 16px;
    color: var(--dark-primary);
    font-size: 56px;
    line-height: 60px;
    font-family: Pockota;
    font-weight: 600;
    text-align: center;
  }

  .message {
    margin-top: 24px;
    font-size: 28px;
    text-align: center;
  }

  .materials-reminder {
    margin-top: 36px;
    color: var(--medium-gray);
    text-align: center;
  }
}

@media screen and (max-width: 568px) {
  .thank-you-wrapper {
    position: relative;
    padding: 36px 20px;

    .content {
      max-width: 100%;
    }

    .title {
      width: 100%;
      margin: 0px;
      margin-bottom: 12px;
      font-size: 40px;
      line-height: 40px;
    }

    .message {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
    }

    .materials-reminder {
      margin-top: 24px;
    }
  }
}
