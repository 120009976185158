.add-class-modal {
  --modal-height: 80vh;
  --modal-header-height: 110px;
  --modal-bottom-buttons-height: 110px;
  --modal-content-height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-bottom-buttons-height));
  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;
  --bottom-buttons-top-margin: 24px;

  position: relative;
  min-height: 100%;
  width: 100%;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 64px;

      .col {
        .section-header {
          margin: 16px 0px;
          font-size: 20px;
          font-weight: 500;

          &.shipping-date {
            margin-top: 64px;
          }
        }
        // flex: 1;

        // &:not(:first-child) {
        //   margin-left: 64px;
        // }

        .location-buttons-container {
          display: flex;
          width: 100%;
          justify-content: flex-end;

          .use-existing-location {
            display: inline-flex;
            align-items: center;
            color: var(--red);
            stroke: var(--red);
            cursor: pointer;
            user-select: none;

            :first-child {
              margin-right: 8px;
            }
          }
          .add-new-location {
            display: inline-flex;
            align-items: center;
            color: var(--green);
            stroke: var(--green);
            cursor: pointer;
            user-select: none;

            :first-child {
              margin-right: 8px;
            }
          }
        }
        .section-container {
          margin-bottom: 24px;

          &.double {
            display: flex;
            flex-direction: row;

            > * {
              flex: 1;

              .checkbox-wrapper {
                height: 40px;
                display: flex;
                align-items: center;
              }

              &.small {
                flex: 0.5;
              }

              &:first-child {
                margin-right: 16px;
              }

              &:last-child {
                margin-left: 16px;
              }

              &:not(:first-child):not(:last-child) {
                margin: 0px 16px;
              }
            }
          }

          .section-title {
            margin-bottom: 8px;
            font-weight: 500;
            color: var(--darkGray);

            &.header {
              font-size: 32px;
            }
          }

          .shipping-date-explanation {
            max-width: 496px;
            margin: 12px 0px 0px 24px;
            color: var(--medium-gray);
            font-weight: 500;
            line-height: 22px;

            a {
              color: var(--orange);
            }
          }
        }
      }
    }

    .long-running-message {
      margin-top: 16px;
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: var(--bottom-buttons-height);
    width: 100%;
    margin-top: var(--bottom-buttons-top-margin);
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--dark-gray);
        border: 2px solid var(--dark-gray);
      }
    }
  }
}
