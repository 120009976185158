.table-wrapper {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 12px;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: var(--shadow);
}

table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  font-variant-numeric: tabular-nums;

  th {
    padding: 10px 0 10px 16px;
    color: var(--dark-primary);
    background-color: var(--super-light-gray);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    vertical-align: bottom;
    border-bottom: 3px solid var(--light-gray);

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    &.center {
      text-align: center;
    }
  }

  td {
    border-bottom: 1px solid #eaeaea;
    padding: 30px 0 30px 16px;
    font-weight: 600;
    vertical-align: top;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    .action {
      color: var(--orange);
      font-weight: 700;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.5;
      }

      + .action {
        margin-top: 4px;
      }

      a {
        color: var(--orange);
        font-weight: 700;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
