.classes {
  --vertical-padding: 32px;
  --title-height: 45px;
  --title-bottom-margin: 16px;
  --upper-height: 175px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.classes-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: var(--header-height);
    margin-bottom: var(--header-bottom-margin);
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          &.disabled {
            opacity: 0.3;

            > span {
              cursor: initial;

              &:hover {
                opacity: 1;
              }

              &:active {
                opacity: 1;
              }
            }
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }

        .action-loading-container {
          margin-top: 10px;
          opacity: 0.3;
        }
      }
    }
  }

  .filter-search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-classes {
      display: flex;
      align-items: center;
      min-width: 400px;

      .filter-classes-label {
        margin-right: 16px;
        font-weight: 500;
      }
    }

    .search-classes {
      min-width: 360px;
    }
  }

  .classes-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);
  }
}

.__react_component_tooltip.place-top::before {
  z-index: -1 !important;
}
